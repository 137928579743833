import { CHANGE_PASSWORD, DELETE_PROFILE_PIC, EMAIL_VERIFY, EMAIL_VERIFY_STATUS, FORGOT_PASSWORD, FORGOT_PASSWORD_STEP, LOGOUT, PROFILE_LOADER, RESET_PASSWORD, SET_EMAIL_VERIFY, SET_EMAIL_VERIFY_OTP, SET_ERROR_MSG, SET_FORGOT_PASSWORD, SET_LOADER, SET_RESET_PASSWORD, SET_UPDATE_PROFILE, SET_USER_DATA, SET_USER_PROFILE, SOCIAL_SIGNUP, UPDATE_PROFILE, UPLOAD_PROFILE_PIC, USER_PROFILE, USER_SIGNUP_STATUS } from '../action-types/authActionTypes';

const initialState = {
    userData: null,
    isLoader: false,
    isProfileLoading: false,
    forgotPasswordStep: null,
    forgotPasswordData: null,
    errorMsg: null,
    resetPasswordData: null,
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_DATA:
            return {
                ...state,
                userData: action.payload,
            }
        case SET_LOADER:
            return {
                ...state,
                isLoader: action.payload,
            }
        case PROFILE_LOADER:
            return {
                ...state,
                isProfileLoading: action.payload,
            }
        case USER_SIGNUP_STATUS:
            return {
                ...state,
                userSignupStatus: action.payload,
            }
        case USER_PROFILE:
            return {
                ...state,
                userProfile: action.payload,
            }
        case SET_USER_PROFILE:
            return {
                ...state,
                userProfile: action.payload,
            }
        case UPDATE_PROFILE:
            return {
                ...state,
                userUpdateProfile: action.payload,
            }
        case SET_UPDATE_PROFILE:
            return {
                ...state,
                userUpdateProfile: action.payload,
            }
        case UPLOAD_PROFILE_PIC:
            return {
                ...state,
                uploadProfilePic: action.payload,
            }
        case DELETE_PROFILE_PIC:
            return {
                ...state,
                uploadProfilePic: action.payload,
            }
        case SOCIAL_SIGNUP:
            return {
                ...state,
                userData: action.payload,
            }
        case FORGOT_PASSWORD_STEP:
            return {
                ...state,
                forgotPasswordStep: action.payload,
            }
        case FORGOT_PASSWORD:
            return {
                ...state,
                forgotPasswordData: action.payload,
            }
        case SET_FORGOT_PASSWORD:
            return {
                ...state,
                forgotPasswordData: action.payload,
            }
        case SET_ERROR_MSG:
            return {
                ...state,
                errorMsg: action.payload,
            }
        case RESET_PASSWORD:
            return {
                ...state,
                resetPasswordData: action.payload,
            }
        case SET_RESET_PASSWORD:
            return {
                ...state,
                resetPasswordData: action.payload,
            }
        case CHANGE_PASSWORD:
            return {
                ...state,
                changePasswordData: action.payload,
            }
        case EMAIL_VERIFY:
            return {
                ...state,
                emailVerifyData: action.payload,
            }
        case SET_EMAIL_VERIFY:
            return {
                ...state,
                emailVerifyData: action.payload,
            }
        case EMAIL_VERIFY_STATUS:
            return {
                ...state,
                emailVerifyStatus: action.payload,
            }
        case SET_EMAIL_VERIFY_OTP:
            return {
                ...state,
                emailVerifyOtpData: action.payload,
            }
        case LOGOUT:
            return initialState; // Reset state to the initial values on logout
        default:
            return state;
    }
};
