import { Button, Carousel, Modal, Tooltip } from 'antd';
import React, { createRef, useRef, useState } from 'react'
import playScreenIcon from '../../assets/play-screen-icon.svg'
import { BiLeftArrow, BiLeftArrowAlt, BiRightArrow, BiRightArrowAlt } from 'react-icons/bi';
import { Link } from 'react-router-dom';

function HowToPlay() {
    const carouselRef = createRef();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0); // Track the current slide

    const totalSlides = 3; // Number of slides


    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const carousel = useRef();

    const onChange = (current) => {
        setCurrentSlide(current);
    };

    const handleNext = () => carousel.current.next();

    const handlePrev = () => carousel.current.prev();

    return (
        <div>
            <Tooltip color={'#333'} placement="bottom" title="How to Play">
                <Link onClick={showModal}>
                    <img className="w-9" src={playScreenIcon} alt='Play Screen Icon'></img>
                </Link>
            </Tooltip>
            <Modal className="how-to-play-modal" title="" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <h2 className="mt-4 mb-5 text-[34px] text-center font-bold uppercase gradient-text-how-to-play roboto-condensed">How To Play</h2>
                <Carousel afterChange={onChange} ref={carousel}>
                    <div>
                        <h3 className="text-[18px] font-semibold mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>
                        <p className="m-0 text-base">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <p className="m-0 text-base">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <div>
                        <h3 className="text-[18px] font-semibold mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>
                        <p className="m-0 text-base">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <p className="m-0 text-base">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <div>
                        <h3 className="text-[18px] font-semibold mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>
                        <p className="m-0 text-base">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <p className="m-0 text-base">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </Carousel>
                <div className="flex justify-between items-center mt-5">
                    <button onClick={handlePrev} className="text-white bg-[#000000] rounded-lg w-[50px] h-11 flex items-center justify-center text-2xl"><BiLeftArrowAlt /></button>
                    <span className="text-[18px] font-semibold">
                        <span>{currentSlide + 1}</span> / <span>{totalSlides}</span> {/* Display current slide count */}
                    </span>                    
                    <button onClick={handleNext} className="text-white bg-[#000000] rounded-lg w-[50px] h-11 flex items-center justify-center text-2xl"><BiRightArrowAlt /></button>
                </div>
            </Modal>
        </div>
    )
}

export default HowToPlay