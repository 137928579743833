import React from 'react'
import AppleSignInButton from 'react-apple-signin-auth'
import appleIcon from '../assets/apple.svg';
import { socialSignup } from '../redux/action/authAction';
import { useDispatch } from 'react-redux';
function AppleSignup() {
    const dispatch = useDispatch()
    const handleAppleCallback = (response) => {
        console.log('apple_resp',response);
        
        if (response?.status === "unknown") {
            console.error('Sorry!', 'Something went wrong with facebook Login.');
            return;
        } else {
            const payload = {
                signupMethod: 'apple',
                socialAuthToken: response?.authorization.code,
                ageConsentInfo: {
                    "device": "web"
                },
            }

            dispatch(socialSignup(payload));
        }

    }
    return (
        <div>
            <AppleSignInButton
                /** Auth options passed to AppleID.auth.init() */
                authOptions={{
                    /** Client ID - eg: 'com.example.com' */
                    clientId: 'com.cryptordle.web',
                    /** Requested scopes, seperated by spaces - eg: 'email name' */
                    
                    scope:'name email',
                    /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
                    redirectURI: 'https://cryptordle-dev-app.scaleupdevops.in/',
                    /** State string that is returned with the apple response */
                    state: 'state',
                    /** Nonce */
                    nonce: 'nonce',
                    /** Uses popup auth instead of redirection */
                    usePopup: true,
                    // usePopup: ${authOptions.usePopup},
                }} // REQUIRED
                /** General props */
                uiType="dark"
                /** className */
                className="apple-auth-btn"
                /** Removes default style tag */
                noDefaultStyle={false}
                /** Allows to change the button's children, eg: for changing the button text */
                buttonExtraChildren="Continue with Apple"
                /** Extra controlling props */
                /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
                onSuccess={(response) =>handleAppleCallback(response)} // default = undefined
                /** Called upon signin error */
                onError={(error) => console.error(error)} // default = undefined
                /** Skips loading the apple script if true */
                skipScript={false} // default = undefined
                /** Apple image props */
              //  iconProp={{ style: { marginTop: '10px' } }} // default = undefined
                /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
                render={(props) => <button {...props} className="h-[60px] w-full bg-white rounded-lg border border-[#6A7186] border-solid cursor-pointer"><img src={appleIcon} alt='appleIcon' /></button>}
            />
        </div>
    )
}

export default AppleSignup