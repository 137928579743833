import React, { useCallback, useState } from 'react'
import profileImg from '../assets/avtar.png'
import Dropzone, { useDropzone } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux';
import { uploadProfilePic } from '../redux/action/authAction';
import DeleteConformation from './modal/DeleteConformation';
import { ClipLoader } from 'react-spinners';

function UploadProfile() {
    const dispatch = useDispatch();
    const profileData = useSelector((state) => state?.auth?.userData);
    const isProfileLoading = useSelector((state) => state?.auth?.isProfileLoading);
    console.log(profileData, "profilePic")
    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0]; // Get the first file
        console.log(file, "file")
        // Create FormData and append the file with the key 'profilePic'
        // This sends the file as binary data

        // Dispatch the formData through your Redux action
        dispatch(uploadProfilePic(file));

        console.log(file, "File Data");

    }, [dispatch]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
        }
    })

    return (
        <>
            <div className="flex items-center gap-5 mb-4">
                <div className={` max-size-28  size-28 flex items-center justify-center bg-slate-100 rounded-full overflow-hidden`}>
                    {isProfileLoading ? (
                        <div className='z-50'>
                            <ClipLoader color='red'/>
                        </div>
                    ) : (
                        <>
                            {profileData?.profilePic ? (
                                <img className="object-cover w-full rounded-full" src={profileData?.profilePic} alt="Profile Img" />
                            ) : (
                                <img className="object-cover w-full rounded-full" src={profileImg} alt="Profile Img" />
                            )}
                        </>
                    )}

                    {/* {profileInitials} */}
                </div>
                {/* <Dropzone onDrop={acceptedFiles => {onDrop()}}>
                    {({ getRootProps, getInputProps }) => (
                        <section> */}
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <button type="button" className="font-inter border border-[#000000] border-solid rounded-lg h-10 bg-transparent min-w-28">Upload new picture</button>
                    {/* <p>Drag 'n' drop some files here, or click to select files</p> */}
                </div>
                {/* </section>
                    )}
                </Dropzone> */}
                <DeleteConformation />
            </div>


            {/* <div {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p>Drop the files here ...</p> :
                        <p>Drag 'n' drop some files here, or click to select files</p>
                }
            </div> */}
        </>

    )
}

export default UploadProfile