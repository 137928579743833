import { Carousel, Modal } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import deleteIcon from '../../assets/delete-icon.png'
import { deleteProfilePic } from '../../redux/action/authAction';
import { PulseLoader } from 'react-spinners';

function DeleteConformation() {
    const dispatch = useDispatch();
    const isLoader = useSelector((state) => state?.auth?.isLoader);
    const [isModalOpen, setIsModalOpen] = useState(false);
    
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const deleteProfile = () => {
        dispatch(deleteProfilePic())
        setTimeout(() => {
            setIsModalOpen(false);
        }, 750)
    }
    return (
        <div>
            <button type="button" onClick={showModal} className="font-inter border-none text-[#fff] rounded-lg h-10 bg-[#F1F5F9] bg-red-600 min-w-28">Delete</button>
            <Modal className="how-to-play-modal" title="" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className="text-center">
                    <img className="w-24" src={deleteIcon} alt='Profile Img'></img>
                    <h3 className="text-2xl font-bold mt-0 mb-2">Are you sure?</h3>
                    <p className="m-0 text-base">Are you sure you want to delete this profile image?</p>
                </div>
                <div className="flex justify-center items-center mt-5">
                    <button onClick={() => (handleCancel())} className="text-white text-base bg-[#000000] rounded-lg min-w-[80px] h-11 flex items-center justify-center">No</button>
                    <button type="submit" onClick={() => (deleteProfile())} className="ml-3 text-white text-base bg-red-600 border-none rounded-lg min-w-[80px] h-11 flex items-center justify-center">{isLoader ? <PulseLoader color="#FFFFFF" /> : "Yes"}</button>
                </div>
            </Modal>
        </div>
    )
}

export default DeleteConformation