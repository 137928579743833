import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneInputComponent = ({ phone, formikProps}) => {
   
    const handlePhoneChange = (value, country) => {
         const dialCode = `+${country.dialCode}`;
        const number = value.replace(dialCode, '').trim(); // Extract phone number excluding the country code

        formikProps.setFieldValue('phoneNumber' , number)
        formikProps.setFieldValue('countryCode' , dialCode)
    };
    return (
        <div className='w-full'>
            <PhoneInput
                country={'us'} // Default country
                value={phone}
                placeholder='Phone (Optional)'
                // className={`mt-1 h-[55px] w-full ps-3 border border-[#6A7186] border-solid rounded-lg mb-4 text-[#6A7186] text-[16px] placeholder:text-[#6A7186] placeholder:font-normal`}
                className={`mt-1 h-[55px] !w-full rounded-lg mb-4 text-[#6A7186] text-[16px] placeholder:text-[#6A7186] placeholder:font-normal`}
                onChange={handlePhoneChange}
                inputStyle={{
                    width: '100%',
                    padding: '10px',
                    borderRadius: '4px',
                }}
            />
        </div>
    );
};
export default PhoneInputComponent;